
              @import "@/assets/css/variables.scss";
            





















































































































// @import '@/assets/css/pages/resetProfilePasswordPug.scss';
.resetPassword_wrapper {
  p {
    font-size: 14px;
    color: $text-secondary;
    line-height: 21px;
  }

  .title {
    font-size: 24px;
    color: #09090D;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
  }

  .no_authroization,
  .reset_success {
    text-align: center;
    margin-top: 24px;
  }

  .reset_success {
    display: flex;
    flex-direction: column;
    align-items: center;

    .go_home_btn {
      margin-top: 24px;
    }
  }

  .el-form-item:nth-child(1) {
    margin-top: 24px;
  }

  .self_label {
    font-size: 16px;
    color: $text-primary;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    @include rtl-sass-prop-dual(text-align, left, text-align, right);

    span {
      font-weight: 400;
      color: $danger;
      @include rtl-sass-prop(margin-right, margin-left, 2px);
    }
  }

  .reset_btn {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    border-radius: 8px;
    background: $secondary;
    margin-top: 16px;
  }

}
